window.GLP = {
    ...(window.GLP || {}),
    methods: {
        getJourneyElement() {
            let journeyStage;
            let journeyFlows = [];
            let currentFlowIndex = 0;

            const flowPath = {
                "start_journey": ['channel', 'reason', 'reason_details'],
                "end_journey": ['reason', 'outcome', 'outcome_details']
            };

            const resetFlowPathValues = (fromGroupName) => {
                const currentFlowPath = flowPath[journeyStage];
                const fromIndex = !fromGroupName ? 0 : currentFlowPath.findIndex(n => n === fromGroupName) + 1;

                for (const groupName of currentFlowPath.slice(fromIndex)) {
                    $(`[data-name="${groupName}"]`).removeClass('active').hide();
                    $(`#journey-${groupName}`).val('');
                    checkHiddenChildren(groupName);
                }

                currentFlowIndex = 0;
            }

            const startUpdateFlow = () => {
                const selectedGroup = $('.journey-type :checked').val();
                const journeyFlow = journeyFlows[journeyStage][selectedGroup];

                updateFlow(selectedGroup, journeyFlow);
            }

            const checkHiddenChildren = (groupName) => {
                const isAllHidden = $(`[data-name="${groupName}"]:hidden`).length === $(`[data-name="${groupName}"]`).length;

                if (isAllHidden) {
                    $(`[data-title="${groupName}"]`).hide();
                } else {
                    $(`[data-title="${groupName}"]`).show();
                }
            }

            const checkValidInput = () => {
                const submitButton = $('#journey-form button[type="submit"]')
                const visibleOptions = $('#journey-form [data-title]:visible')

                for(const visibleOption of visibleOptions) {
                    const input = $(`#journey-form input[type="hidden"]#journey-${$(visibleOption).data('title')}`)

                    if (!input.length) {
                        continue;
                    }

                    if (!(input.val()?.trim()).length) {
                        submitButton.attr('disabled', 'disabled')
                        return
                    }
                }

                submitButton.removeAttr('disabled')
            }

            const updateFlow = (selectedGroupName, journeyFlow) => {
                const currentFlowPath = flowPath[journeyStage];
                const selectedGroup = $(`#journey-${selectedGroupName}`).val();

                // If the selected group (hidden input) doesn't exist => end of recursion
                if (!selectedGroup) {
                    currentFlowIndex = 0;
                    return;
                }

                // Update journey flow element visibilities
                const groupName = currentFlowPath[currentFlowIndex];
                const journeyFlowElements = Array.isArray(journeyFlow) ? journeyFlow : Object.keys(journeyFlow);

                // If there's no sub-flows => show all elements in current path
                if (!journeyFlowElements) {
                    currentFlowIndex = 0;
                    return;
                }

                // Hide all elements then only show those of the current flow path
                $(`[data-name="${groupName}"]`).hide();

                for (const element of journeyFlowElements) {
                    $(`[data-name="${groupName}"][data-value="${element}"]`).show();
                }

                // If all are hidden also hide the title text
                checkHiddenChildren(groupName);
        

                // Progress to the next path and get the next level in the flow
                currentFlowIndex++;
                const nextJourneyFlow = journeyFlow[$(`#journey-${groupName}`).val()] ?? [];

                checkValidInput()

                if (nextJourneyFlow) {
                    return updateFlow(groupName, nextJourneyFlow);
                }

                currentFlowIndex = 0;
            }

            const getEventOfType = (eventType) => {
                return window.GLP.data.currentJourney?.events.find(event => event.type === eventType)
            }

            $(document).on('click', '.journey-pill', function (e) {
                const groupName = $(this).data('name');

                $(`.journey-pill[data-name="${groupName}"]`).removeClass('active');
                $(e.target).addClass('active');

                $(`#journey-${groupName}`).val($(e.target).text().trim());

                resetFlowPathValues(groupName);
                
                startUpdateFlow();
            });

            $(document).on('click', '.journey-type', function() {
                resetFlowPathValues();

                startUpdateFlow();
            });

            $(document).on('')


            return () => $.get(window.GLP.routes.journeyComponentRoute)
                .done(journeyData => {
                    $('#journey-form').html(journeyData.journeyElement);

                    fetch('/journey-options-v2.json?' + Math.random())
                        .then(response => response.json())
                        .then(data => {
                            journeyFlows = data.flows;
                            journeyStage = $('[name="submit_type"]').val();
                            const journeyType = journeyData.currentJourney ? (journeyData.currentJourney.is_outgoing ? 'outgoing' : 'incoming') : null;
                            const elementGroups = data.definitions.filter(d => !d.stage || d.stage === journeyStage);
                            const $journeyForm = $(`#${journeyStage}_form #journey-elements`);
                            const journeyReason = $(`#${journeyStage}_form #journey-reason`).val();
                            
                            let journeyFlowElements = journeyType ? journeyFlows[journeyStage][journeyType][journeyReason] : [];

                            for (const elementGroup of elementGroups) {
                                $journeyForm.append(`<h5 class="mt-3" data-title="${elementGroup.name}">${elementGroup.title}</h5>`);

                                if (elementGroup.type === "radio") {
                                    for(const element of elementGroup.elements) {
                                        let isChecked = (journeyData?.currentJourney?.is_outgoing && element.toLowerCase() === 'outgoing') || element.selected === element.toLowerCase();

                                        if (!isChecked && journeyData?.currentJourney) {
                                            isChecked = element.toLowerCase() === 'incoming';
                                        }
        
                                        $journeyForm.append(`
                                            <div class="form-check form-check-inline journey-type ${journeyStage === 'end_journey' ? 'd-none' : ''}" data-name="${elementGroup.name}">
                                                <input class="form-check-input" 
                                                        id="journey-${element.toLowerCase()}" 
                                                        type="radio" 
                                                        name="${elementGroup.name}" 
                                                        value="${element.toLowerCase()}" 
                                                        ${isChecked ? 'checked' : ''}
                                                >
                                                <label class="form-check-label" for="journey-${element.toLowerCase()}">${element}</label>
                                            </div>
                                        `);
                                    }
                                }

                                if (elementGroup.type === "input") {
                                    $journeyForm.append(`
                                        <div class="input-group has-validation" 
                                                data-name="${elementGroup.name}" 
                                                data-value="${elementGroup.name}" 
                                                style="display:none">
                                            <input type="text" 
                                                    class="form-control" 
                                                    name="${elementGroup.name}"
                                                    id="journey-${elementGroup.name}" 
                                                    ${elementGroup.required ? 'required' : ''}
                                            >
                                            <div id="journey-${elementGroup.name}-error" class="invalid-feedback">
                                                Required.
                                            </div>
                                        </div>
                                    `);
                                }

                                let journeyElements = journeyFlowElements.length ? journeyFlowElements : (elementGroup.elements ?? []);

                                for(const element of journeyElements) {
                                    const groupName = elementGroup.name;
                                    const isChecked = elementGroup.selected === element || element === getEventOfType(groupName)?.details;

                                    $journeyForm.append(`
                                        <a class="journey-pill me-3 mb-2 ${isChecked ? 'active' : ''}" 
                                            data-name="${elementGroup.name}" 
                                            data-value="${element}" 
                                            style="display:none"
                                        >
                                            ${element}
                                        </a>
                                    `);

                                    if (isChecked) {
                                        $(`#journey-${groupName}`).val(element);
                                    }
                                }

                                checkHiddenChildren(elementGroup.name);
                            }

                            startUpdateFlow();
                        });
                })

        }
    }
}