document.addEventListener('DOMContentLoaded', () => {
    // const copyButton = document.querySelector('.copyButton');
    document.querySelectorAll('.copy-on-click').forEach(el => el.addEventListener('click', (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(e.currentTarget.dataset.copyContent)
        .then(() => {
            console.log('Copying to clipboard was successful!');
        })
        .catch((err) => {
            console.error('Could not copy text:', err);
        });
    }));
});
