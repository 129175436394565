import { Tooltip, Toast, Popover } from "bootstrap";
import "./bootstrap.js";
import "./clipboard.js";
import "./customer.js";
import "./journey.js";
import "./lead.js";

import { DateTime } from "luxon";

window.DateTime = DateTime;
