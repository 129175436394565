var tabCount = 0;
$(document).on("keydown", function (event) {
    if (event.which == 9) {
        event.preventDefault();
        if (tabCount == 4) {
            tabCount = 0;
        } else {
            tabCount++;
        }
        $('#customer-tabs li:eq(' + tabCount + ') button').tab('show')
    }
});

$( document ).ready(function() {
    $('#addNoteButton').click(function(event){
        $('#customer-tabs li:eq(4) button').tab('show');
        $('#addNoteInput').focus();
    });
});

