$( document ).ready(function() {
    const leadStatusDropdown = $("#lead-status-dropdown");
    let prevStatusValue = leadStatusDropdown.val();

    leadStatusDropdown.on('change', function() {
        let curStatusValue = $(this).val();
        
        const toastElem = $('.toast');
        const toastBodyElem = $('.toast-body', toastElem);
        const toastObj = bootstrap.Toast.getOrCreateInstance(toastElem.get(0)); // Returns a Bootstrap toast

        toastElem.on('hidden.bs.toast', function(){
            let toastElemClassName = toastElem.attr('class');
            toastElem.attr('class', toastElemClassName.replace(/bg\-([a-z]+)\s?/, ''));
            toastBodyElem.text('');

            toastElem.off('hidden.bs.toast');
        });

        // Don't do anything if for some reason the value remains unchanged
        if(prevStatusValue === curStatusValue) return;

        prevStatusValue = curStatusValue;

        // Turn the form into an AJAX request instead
        const leadStatusForm = $('#lead-status-form');

        $.ajax({
            url: leadStatusForm.attr('action'),
            method: leadStatusForm.attr('method'),
            data: leadStatusForm.serialize()
        })
        .then(res => {
            toastElem.addClass('bg-primary');
            toastBodyElem.text('Lead status updated');
        })
        .catch(err => {
            console.log('Error updating lead status:', err);
            toastElem.addClass('bg-danger');
            toastBodyElem.text('Lead status update failed');
        })
        .done(() => {
            toastObj.show();
        });
    });
});